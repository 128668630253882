import { RootState } from "../store";

export const selectIsEmployeeOnboarding = (state: RootState) => {
  return state.onboarding.user.email !== ""
    ? !state.onboarding.user.isCompanyAdmin
    : null;
};

export const selectOnboardingUser = (state: RootState) => state.onboarding.user;

export const selectOnboardingNewPassword = (state: RootState) =>
  state.onboarding.password;

export const selectOnboardingOrganizationStructure = (state: RootState) =>
  state.onboarding.organizationStructure;

export const selectOnboardingCompany = (state: RootState) =>
  state.onboarding.company;

export const selectOnboardingLoading = (state: RootState) =>
  state.onboarding.loading;

export const selectOnboardingProgress = (state: RootState) =>
  state.onboarding.progress;

export const selectOnboardingToken = (state: RootState) =>
  state.onboarding.token;

export const selectOnboardingLogin = (state: RootState) =>
  state.onboarding.login;

export const selectOnboardingUserHasBeenFetched = (state: RootState) =>
  state.onboarding.hasUserBeenFetched;

export const selectOnboardingIsPhoneNumberValid = (state: RootState) =>
  state.onboarding.isPhoneNumberValid;

export const selectOnboardingIsPhoneNumberValidating = (state: RootState) =>
  state.onboarding.isPhoneNumberValidating;

export const selectOnboardingEmployer = (state: RootState) => ({
  company: state.onboarding.company,
  user: state.onboarding.user,
  password: state.onboarding.password,
  organizationStructure: state.onboarding.organizationStructure,
});

export const selectOnboardingEmployerError = (state: RootState) => ({
  error: state.onboarding.error,
  message: state.onboarding.errorMessage,
  details: state.onboarding.errorDetails,
});

export const selectOnboardingEmployee = (state: RootState) =>
  state.onboarding.password;

export const selectShowWelcomeMessage = (state: RootState) =>
  state.onboarding.showWelcomeMessage;
