import { SnackbarKey, SnackbarProvider } from "notistack";
import React, { FC } from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const MaxCoexistingSnackbars = 5;

const AppSnackbarProvider: FC<{ maxSnackbars?: number }> = ({
  children,
  maxSnackbars,
}) => {
  const notistackRef: React.Ref<SnackbarProvider> = React.createRef();
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={maxSnackbars || MaxCoexistingSnackbars}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={10000}
      action={(key) => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClickDismiss(key)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default AppSnackbarProvider;
